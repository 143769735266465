import React, { useRef, useState, useEffect, useCallback } from "react";
import FileSaver from 'file-saver';
import { Map, TileLayer, Marker } from "react-leaflet";

import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";
import Switch from "react-switch";
import { FaTrash } from "react-icons/fa";

import history from "../../../../services/history";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  Label,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { TrIfoodRestaurants } from "views/pages/styles";
import { useRestaurants } from "context/RestaurantsContext";
import { useAuth } from "context/AuthContext";


import { ButtonTab } from "./styles";
import Select from "components/Form/SelectInput";
import { LinkStyled } from "components/Cards/styles";
import CheckboxInput from "../../../components/Checkbox";
import Input from "../../../components/Input";
import RowDivider from "../../../components/RowDivider";
import AvatarInputGd from "../../../components/AvatarInputGd";
import RecoverMenu from "./RecoverMenu";

import { generateProductsSheet } from "services/generateProductsSheet";
import apiGd from "../../../../services/apiGd";
import InputMask from "react-input-mask";
import { format, isBefore } from "date-fns";
import ReactInputMask from "react-input-mask";


function EditRestaurantGD(props) {
  const formRef = useRef(null);
  const notificationAlert = useRef(null);
  const { id } = props.match.params;
  const [restaurant, setRestaurant] = useState([]);
  const token = localStorage.getItem("@QRExpress:tokenGd");
  const { getRestaurants } = useRestaurants();
  const { user } = useAuth();

  const [nfceCheck, setNfceCheck] = useState(false);
  const [clubeCheck, setClubeCheck] = useState(false);
  // const [nfceToken, setNfceToken] = useState(null);
  const [deleteClubeModal, setDeleteClubeModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [asaasId, setAsaasId] = useState(null);
  const [onlyDelivery, setOnlyDelivery] = useState(false);
  const [onlyQrcode, setOnlyQrcode] = useState(false);
  const [useStock, setUseStock] = useState(false);
  const [useWpp, setUseWpp] = useState(false);

  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState(false);
  const [isWithdrawalAllowed, setIsWithdrawalAllowed] = useState(false);
  const [isDeliveryAddressesAllowed, setIsDeliveryAddressesAllowed] =
    useState(false);
  const [isPixAllowed, setIsPixAllowed] = useState(false);
  const [isCreditCardAllowed, setIsCreditCardAllowed] = useState(false);

  const [hasStonePos, setHasStonePos] = useState(false);
  const [hasStonePdv, setHasStonePdv] = useState(false);
  const [skStone, setSkStone] = useState('');

  const [buttonTab, setButtonTab] = useState(1);

  const [suspensionAlert, setSuspensionAlert] = useState(false);

  const [restHasIfood, setRestHasIfood] = useState(false);

  const [hasNickname, setHasNickname] = useState(false);
  const [nickname, setNickname] = useState("");
  const [plan, setPlan] = useState();
  const [passwordPanel, setPasswordPanel] = useState(false);
  const [smsService, setSmsService] = useState(false);
  const [clientPaySms, setClientPaySms] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [nameIfood, setNameIfood] = useState("");
  const [merchantIfood, setMerchantIfood] = useState("");
  const [ifoodRestaurants, setIfoodRestaurants] = useState([]);
  const [asaasRestaurants, setAsaasRestaurants] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasBalcony, setHasBalcony] = useState(false);
  const [responsibleUser, setResponsibleUser] = useState(null);
  const [ifoodPdv, setIfoodPdv] = useState(false);

  const [name, setName] = useState("");
  const [instagram, setInstagram] = useState("");

  const [onlyQrcodeDisabled, setOnlyQrcodeDisabled] = useState(false);

  const [tableLimitType, setTableLimitType] = useState("unlimited-tables");
  const [isTableLimitDisabled, setIsTableLimitDisabled] = useState(true);
  const [tableLimitValue, setTableLimitValue] = useState(null);
  const [tableLimitTypeDefault, setTableLimitTypeDefault] = useState({});

  function changeBalconyCheck() {
    setHasBalcony(!hasBalcony);
  }

  async function updatePassword() {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      if (newPassword.length < 6) {
        notify("tr", "danger", "Senha tem que ter no mínimo 6 caracteres");
      } else {
        const data = { password: newPassword };
        await apiGd.put(`admin/restaurants/password/${id}`, data, reqConfig);
        toggleModalPassword();
        notify("tr", "success", "Senha atualizada com sucesso!");
      }
    } catch (err) {
      notify("tr", "danger", "Erro ao atualizar senha");
    }
  }

  function changeNfceCheck() {
    setNfceCheck(!nfceCheck);
  }

  function changeClubeCheck() {
    setClubeCheck(!clubeCheck);
  }

  function changePixAllowed() {
    setIsPixAllowed(!isPixAllowed);
  }

  function changeCreditCardAllowed() {
    setIsCreditCardAllowed(!isCreditCardAllowed);
  }

  function changeOnlyDelivery() {
    setOnlyDelivery(!onlyDelivery);
    if (!onlyDelivery === true) {
      setOnlyQrcode(true);
      setOnlyQrcodeDisabled(true);
    } else {
      setOnlyQrcodeDisabled(false);
    }
    setIsDeliveryAllowed(true);
  }

  function changeOnlyQrcode() {
    setOnlyQrcode(!onlyQrcode);
  }

  function changeDeliveryAllowed() {
    setIsDeliveryAllowed(!isDeliveryAllowed);
  }

  function changeWithdrawalAllowed() {
    setIsWithdrawalAllowed(!isWithdrawalAllowed);
  }

  function changeDeliveryAddressesAllowed() {
    setIsDeliveryAddressesAllowed(!isDeliveryAddressesAllowed);
  }

  function changeUseStock() {
    setUseStock(!useStock);
  }

  function changeSmsService() {
    setSmsService(!smsService);

    if (smsService) {
      setClientPaySms(false);
    }
  }

  function changeClientPaySms() {
    setClientPaySms(!clientPaySms);
  }

  function changeSuspensionAlert() {
    setSuspensionAlert(!suspensionAlert);
  }

  function changePasswordPanel() {
    setPasswordPanel(!passwordPanel);

    if (passwordPanel) {
      setSmsService(true);
    }
  }

  const [locationLimitedCheck, setLocationLimitedCheck] = useState();
  function changeLocationCheck() {
    setLocationLimitedCheck(!locationLimitedCheck);
  }

  const [modal, setModal] = useState(false);
  function toggleModal() {
    setModal(!modal);
  }

  const [modalPassword, setModalPassword] = useState(false);
  function toggleModalPassword() {
    setModalPassword(!modalPassword);
  }

  const getUsers = useCallback(async () => {
    try {
      const res = await apiGd.get("/restaurants/users", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });

      //removendo o usuario Admin/Padrao do nosso dash
      const users = res.data
        .filter((data) => data.id !== 1)
        .map((data) => {
          return {
            label: data.name,
            value: data.id,
          };
        });

      setUsers(users);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };

    notificationAlert.current.notificationAlert(options);
  };

  const [inicialLocation, setInicialLocation] = useState([0, 0]);
  const [selectedPosition, setSelectedPosition] = useState([0, 0]);
  const [useSmartPos, setUseSmartPos] = useState(false);
  const [useTablet, setUseTablet] = useState(false);

  const [ownerWpp, setOwnerWpp] = useState('');
  const [wppReportActive, setWppReportActive] = useState(false);

  function changeUseSmartPos() {
    setUseSmartPos(!useSmartPos);
  }

  const getInfo = useCallback(async () => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await apiGd.get(`admin/restaurants/${id}`, reqConfig);

      setRestaurant(response.data);

      setResponsibleUser(response.data.responsible_user_id || null);

      if (!!response.data.longitude && !!response.data.latitude) {
        setInicialLocation([response.data.latitude, response.data.longitude]);
        setSelectedPosition([response.data.latitude, response.data.longitude]);
      }

      setName(response.data.name);
      setInstagram(response.data.instagram);
      setLocationLimitedCheck(response.data.is_location_limited);
      setNfceCheck(response.data.has_nfce);
      setRestHasIfood(response.data.has_ifood);
      setOwnerWpp(response.data.owner_wpp);
      setHasNickname(response.data.nickname ? true : false);
      setNickname(response.data.nickname);
      // setNfceToken(response.data.nfce_token);
      setClubeCheck(response.data.has_clube);
      setIfoodRestaurants(response.data.ifood_restaurants);
      setIsActive(response.data.is_active);
      setSuspensionAlert(response.data.suspension_alert);
      setHasBalcony(response.data.settings.has_balcony);
      setUseSmartPos(response.data.settings.use_smart_pos);
      setUseTablet(response.data.settings.use_tablet);

      setHasStonePos(response.data.has_stone_pos);
      setHasStonePdv(response.data.settings.has_stone_pdv);
      setSkStone(response.data.sk_stone);

      setIfoodPdv(response.data.settings.use_ifood_pdv_code);
      setWppReportActive(response.data.settings.wpp_report_active);

      if (response.data?.settings?.plan) {
        setPlan({
          label: response.data?.settings?.plan,
          value: response.data?.settings?.plan,
        });
      }

      if (!!response.data.only_delivery === true) {
        setOnlyQrcodeDisabled(true);
      }

      setOnlyDelivery(!!response.data.only_delivery);
      setOnlyQrcode(response.data.only_qrcode);
      setUseStock(!!response.data.use_stock);
      setUseWpp(!!response.data.use_wpp);
      setIsPixAllowed(!!response.data.has_pix);
      setIsCreditCardAllowed(!!response.data.has_credit_card);
      setTableLimitType(response.data.table_limit_type);
      setTableLimitValue(
        response.data.table_limit ? parseInt(response.data.table_limit) : null
      );

      switch (response.data.table_limit_type) {
        case "only-balcony":
          setTableLimitTypeDefault({
            value: response.data.table_limit_type,
            label: "Somente balcão",
          });
          setIsTableLimitDisabled(true);
          break;
        case "limited-tables":
          setTableLimitTypeDefault({
            value: "limited-tables",
            label: "Limitar mesas",
          });
          setIsTableLimitDisabled(false);
          break;
        case "unlimited-tables":
          setTableLimitTypeDefault({
            value: "unlimited-tables",
            label: "Mesas ilimitadas",
          });
          setIsTableLimitDisabled(true);
          break;
        default:
          break;
      }

      const delivery_info = response.data.delivery_info || {};

      setIsDeliveryAllowed(!!delivery_info.is_delivery_allowed);
      setIsWithdrawalAllowed(!!delivery_info.is_withdrawal_allowed);
      setIsDeliveryAddressesAllowed(!!delivery_info.allow_delivery_addresses);

      setSmsService(!!delivery_info.has_sms_service);
      setClientPaySms(!!delivery_info.client_pay_sms);
      setPasswordPanel(!!delivery_info.is_sms_service_optional);

      //asaas
      if (!response.data.asaas_id) {
        try {
          const responseAsaas = await apiGd.get(
            `admin/asaas-customers`,
            reqConfig
          );

          setAsaasRestaurants(responseAsaas.data?.response_array);

          let asaasDefault = [{ label: "Selecione..", value: null }];
          // if(response.data.asaas_id){
          //   asaasDefault = responseAsaas.data?.response_array.find(
          //     (rest) => rest.value === response.data.asaas_id
          //   );
          // }

          setAsaasDefault(asaasDefault);
          // setAsaasId(asaasDefault?.value);
        } catch (err) {
          notify("tr", "danger", "Erro ao carregar informações Asaas.");
        }
      }
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  const [loadingAsaas, setLoadingAsaas] = useState(false);

  const getAsaas = useCallback(async () => {
    setLoadingAsaas(true);
    try {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const responseAsaas = await apiGd.get(`admin/asaas-customers`, reqConfig);

      setAsaasRestaurants(responseAsaas.data?.response_array);

      let asaasDefault = [{ label: "Selecione..", value: null }];
      if (restaurant.asaas_id) {
        asaasDefault = responseAsaas.data?.response_array.find(
          (rest) => rest.value === restaurant.asaas_id
        );
      }

      setAsaasDefault(asaasDefault);
      setAsaasId(asaasDefault?.value);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações Asaas.");
    }
    setLoadingAsaas(false);
  }, [restaurant, token]);

  const deleteMenu = useCallback(async () => {
    if (window.confirm("Tem certeza que deseja deletar esse cardapio?")) {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        await apiGd.put(
          `admin/restaurants/menu/delete`,
          {
            restaurant_id: id,
          },
          reqConfig
        );

        notify("tr", "success", "Cardápio apagado.");
      } catch (err) {
        notify("tr", "danger", "Erro ao carregar informações Asaas.");
      }
    }
  }, [token, id]);

  const handleDownloadSheet = useCallback(async () => {
    try {
      const response = await apiGd.get( `/admin/products-fiscal/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { products, complements } = response.data;

      const sheet = await generateProductsSheet(
        products,
        complements
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Fiscal de produtos.xlsx`);

      notify("tr", "success", "Sucesso");
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informções de produtos.");
    }
  }, [token, id]);

  const submitDeleteClube = async () => {
    if (confirmDelete === "DELETAR") {
      try {
        apiGd.delete(
          `/restaurants/clube/delete/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        notify("tr", "success", "Clube deletado com sucesso.");
        setDeleteClubeModal(false);
        setClubeCheck(false);
      } catch (err) {
        notify("tr", "danger", "Erro ao deletar clube.");
      }
    } else {
      notify("tr", "danger", "Digite DELETAR para confirmar.");
    }
  };

  const createTablePos = useCallback(async () => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      await apiGd.post(`admin/tables/pos/${id}`, null, reqConfig);

      notify("tr", "success", "Mesa criada.");
    } catch (err) {
      notify("tr", "danger", "Erro ao criar mesa. Tente novamente");
    }
  }, [token, id]);

  const handleAddIfoodId = async () => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (merchantIfood === "" || nameIfood === "") {
      notify("tr", "danger", "Preencha nome e merchant id do ifood");
    } else {
      try {
        const data = {
          restaurant_id: id,
          name: nameIfood,
          merchant_id: merchantIfood,
        };
        const response = await apiGd.post(
          `admin/restaurants/ifood/merchant`,
          data,
          reqConfig
        );
        setNameIfood("");
        setMerchantIfood("");
        setIfoodRestaurants((state) => [...state, response.data]);
        notify("tr", "success", "Inserido com sucesso");
      } catch (err) {
        notify("tr", "danger", "Erro ao inserir.");
      }
    }
  };

  const handleDeleteIfoodId = async (ifood_merchant_id) => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        restaurant_id: id,
        merchant_id: ifood_merchant_id,
      },
    };

    try {
      await apiGd.delete(`admin/restaurants/ifood/merchant`, reqConfig);
      setIfoodRestaurants((state) =>
        state.filter((rest) => rest.ifood_merchant_id !== ifood_merchant_id)
      );
      notify("tr", "success", "Deletado com sucesso");
    } catch (err) {
      notify("tr", "danger", "Erro ao remover.");
    }
  };

  async function updateProfile(data) {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (data.delivery_info.is_delivery_allowed || data.only_delivery) {
      data.delivery_info.is_withdrawal_allowed = true;
    } else {
      data.delivery_info.is_withdrawal_allowed = false;
    }

    try {
      data.is_location_limited = locationLimitedCheck;
      data.has_nfce = nfceCheck;
      data.regime_tributario = data.regime_tributario || "1";
      data.has_clube = clubeCheck;
      data.owner_wpp = ownerWpp !== '' ? ownerWpp : null;
      data.adress = {
        city: data.city || null,
        neighborhood: data.neighborhood || null,
        state: data.state || null,
        street: data.street || null,
        zip_code: data.zip_code || null,
        number: data.number || -1,
      };
      data.asaas_id = asaasId || restaurant.asaas_id;

      data.only_qrcode = onlyQrcode;
      data.settings = {
        has_balcony: hasBalcony,
        use_smart_pos: useSmartPos,
        plan: plan ? plan.value : null,
        use_tablet: useTablet,
        has_stone_pdv: hasStonePdv,
        use_ifood_pdv_code: ifoodPdv,
        wpp_report_active: wppReportActive
      };

      if(!wppReportActive){
        data.settings.wpp_report_test = false;
      }

      if (clubeCheck) {
        data.model_clube = "cashback";
      } else {
        data.token_clube = null;
      }

      data.name = data.name?.toLowerCase();

      if(data.nickname){
        data.nickname = data.nickname?.toLowerCase();
      }

      if (!hasNickname) {
        data.nickname = null;
      }

      let table_limit_type = tableLimitType;
      let table_limit = data.table_limit;

      if (tableLimitType === "limited-tables-10") {
        table_limit_type = "limited-tables";
        table_limit = 10;
      } else if (tableLimitType === "limited-tables-30") {
        table_limit_type = "limited-tables";
        table_limit = 30;
      } else if (tableLimitType === "limited-tables") {
        if (!table_limit || table_limit === "") {
          notify("tr", "danger", "Limite o numero de mesas.");
          return false;
        }
      }

      data.table_limit_type = table_limit_type || tableLimitType;
      data.table_limit = table_limit
        ? parseInt(table_limit)
        : tableLimitValue
        ? parseInt(tableLimitValue)
        : null;

      if(data.has_pix === false && data.has_credit_card === false){
        data.paytime_seller_id = null;
      }

      if(data.cnpj){
        data.cnpj = data.cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-','');
      }

      console.log("data", data);
      const response = await apiGd.put(
        `admin/restaurants/${id}`,
        data,
        reqConfig
      );
      setRestaurant(response.data);
      setLatitude(response.data.latitude);
      // getRestaurants();
      notify("tr", "success", "Atualizado com sucesso!");
    } catch (err) {
      notify(
        "tr",
        "danger",
        err?.response?.data?.error || err?.response?.data?.message || "Erro ao atualizar."
      );
      console.log(err.message);
    }
  }

  async function deleteRestaurant() {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      await apiGd.delete(`admin/restaurants/${id}`, reqConfig);

      toggleModal();
      getRestaurants();
      history.goBack();
    } catch (e) {
      notify("tr", "danger", "Erro ao apagar restaurantes.");
    }
  }

  async function changeIsActive() {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const data = {
        is_active: !isActive,
      };

      const response = await apiGd.put(
        `admin/restaurants/${id}`,
        data,
        reqConfig
      );

      setIsActive(response.data.is_active);
    } catch (e) {
      notify("tr", "danger", "Erro ao ativar/desativar restaurantes.");
    }
  }

  let initialData = {
    fantasy_name: restaurant.fantasy_name,
    name: restaurant.name,
    nickname: restaurant.nickname,
    email: restaurant.email,
    phone: restaurant.phone,
    avatar_id: restaurant.avatar,
    longitude: restaurant.longitude,
    latitude: restaurant.latitude,
    is_location_limited: restaurant.is_location_limited,
    nfce_token: restaurant.nfce_token,
    cnpj: restaurant.cnpj,
    token_clube: restaurant.token_clube,
    paytime_seller_id: restaurant.paytime_seller_id,
    delivery_info: restaurant.delivery_info || {},
    ifood_merchant_uuid: restaurant.ifood_merchant_uuid,
    instagram: restaurant.instagram,
    city: restaurant.adress?.city,
    neighborhood: restaurant.adress?.neighborhood,
    state: restaurant.adress?.state,
    street: restaurant.adress?.street,
    zip_code: restaurant.adress?.zip_code,
    number: restaurant.adress?.number < 0 ? "" : restaurant.adress?.number,
    use_wpp: restaurant.use_wpp,
    regime_tributario: restaurant.regime_tributario,
    responsible_user_id: restaurant.responsible_user_id || null,
    sk_stone: restaurant.sk_stone
  };

  const [zipCodeState, setZipCodeState] = useState(restaurant.adress?.zip_code);

  function handleMapClick(event) {
    setSelectedPosition([event.latlng.lat, event.latlng.lng]);
    setLatitude(event.latlng.lat);
    setLongitude(event.latlng.lng);
    document.getElementById("latitude").value = event.latlng.lat;
    document.getElementById("longitude").value = event.latlng.lng;
  }

  const [latitudeHere, setLatitudeHere] = useState(0);
  const [longitudeHere, setLongitudeHere] = useState(0);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitudeHere(position.coords.latitude);
      setLongitudeHere(position.coords.longitude);
    });
    getInfo();
    getUsers();
  }, [getInfo, getUsers]);

  const [latitude, setLatitude] = useState(initialData.latitude);
  const [longitude, setLongitude] = useState(initialData.longitude);
  const [asaasDefault, setAsaasDefault] = useState(null);

  function searchLocation() {
    if (latitude !== undefined && longitude !== undefined) {
      setInicialLocation([latitude, longitude]);
      setSelectedPosition([latitude, longitude]);
    } else {
      setInicialLocation([0, 0]);
      setSelectedPosition([0, 0]);
      document.getElementById("latitude").value = 0;
      document.getElementById("longitude").value = 0;
    }
  }

  function locationHere() {
    document.getElementById("latitude").value = latitudeHere;
    document.getElementById("longitude").value = longitudeHere;
    setInicialLocation([latitudeHere, longitudeHere]);
    setSelectedPosition([latitudeHere, longitudeHere]);
    setLatitude(latitudeHere);
    setLongitude(longitudeHere);
  }

  const [excelFile, setExcelFile] = useState(null);

  const sendExcel = useCallback(
    async (data) => {
      try {
        if (excelFile) {
          const formData = new FormData();
          formData.append("file", excelFile, excelFile.name);
          const reqConfig = {
            headers: { Authorization: `Bearer ${token}` },
          };

          await apiGd.post(
            `/restaurants/excel-files/${id}`,
            formData,
            reqConfig
          );

          notify("tr", "success", "Cardápio atualizado com sucesso!");
        } else {
          notify("tr", "danger", "Selecione um arquivo antes!");
        }
      } catch (error) {
        console.log(error);
        notify(
          "tr",
          "danger",
          "Erro ao atualizar cardapio! Confira as informacoes no excel"
        );
      }
    },
    [excelFile, id, token]
  );

  function handleSetFile(e) {
    const file = e.target.files[0];
    setExcelFile(file);
  }

  const [validCep, setValidCep] = useState(true);

  async function handleSearchCep(e) {
    if (e.target.value.charAt(8) !== "_") {
      const cep = e.target.value.replace("-", "").replace(".", "");

      const response = await apiGd.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        const streetInput = formRef.current.getFieldRef("street");
        const neighboorhoodInput = formRef.current.getFieldRef("neighborhood");
        const cityInput = formRef.current.getFieldRef("city");
        const stateInput = formRef.current.getFieldRef("state");
        const zipCodeInput = formRef.current.getFieldRef("zip_code");
        streetInput.value = response.data.logradouro;
        neighboorhoodInput.value = response.data.bairro;
        cityInput.value = response.data.localidade;
        stateInput.value = response.data.uf;
        zipCodeInput.value = response.data.cep;
        setValidCep(true);
      } else {
        const streetInput = formRef.current.getFieldRef("street");
        const neighboorhoodInput = formRef.current.getFieldRef("neighborhood");
        const cityInput = formRef.current.getFieldRef("city");
        setValidCep(false);
        streetInput.value = "";
        neighboorhoodInput.value = "";
        cityInput.value = "";
      }
    }
  }

  const tableOptions = [
    { value: "only-balcony", label: "Somente balcão" },
    { value: "limited-tables-10", label: "Limite de 10 mesas" },
    { value: "limited-tables-30", label: "Limite de 30 mesas" },
    { value: "limited-tables", label: "Limitar mesas" },
    { value: "unlimited-tables", label: "Mesas ilimitadas" },
  ];

  const regimeTributarioOptions = [
    { value: "1", label: "Simples Nacional" },
    { value: "3", label: "Regime Normal" },
  ];

  useEffect(() => {
    switch (tableLimitType) {
      case "unlimited-tables":
        setTableLimitValue("");
        break;
      case "limited-tables-10":
        setTableLimitValue(10);
        break;
      case "limited-tables-30":
        setTableLimitValue(30);
        break;
      case "limited-tables":
        setTableLimitValue("");
        break;
      case "only-balcony":
        setTableLimitValue("");
        break;
    }
  }, [tableLimitType]);

  const [deletedMenu, setDeletedMenu] = useState([]);
  const [loadingDeletedMenu, setLoadingDeletedMenu] = useState([]);

  async function getDeletedMenu(e) {
    setLoadingDeletedMenu(true);
    try {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await apiGd.get(
        `/admin/restaurants/menu/deleted/${id}`,
        reqConfig
      );

      setDeletedMenu(response.data);
    } catch (error) {
      console.log(error);
      notify("tr", "danger", "Erro ao atualizar cardapio deletado");
    }
    setLoadingDeletedMenu(false);
  }

  function changeUseWpp() {
    setUseWpp(!useWpp);
  }

  const typeOfPlansOptions = [
    { value: "BÁSICO", label: "BÁSICO" },
    { value: "INTERMEDIÁRIO", label: "INTERMEDIÁRIO" },
    { value: "INOVAÇÃO", label: "INOVAÇÃO" },
    { value: "PRO", label: "PRO" },
    { value: "APENAS CARDÁPIO", label: "APENAS CARDÁPIO" },
  ];

  function handleChangeTypeOfPlans(e) {
    setPlan({
      label: e,
      value: e,
    });

    if (e === "APENAS CARDÁPIO") {
      setOnlyQrcode(true);
      setOnlyDelivery(false);
      setIsDeliveryAllowed(false);
      setIsPixAllowed(false);
      setIsCreditCardAllowed(false);
      setNfceCheck(false);
      setClubeCheck(false);
      setUseWpp(false);
      setIsDeliveryAddressesAllowed(false);
      setHasBalcony(false);
    }

    if (e === "BÁSICO") {
      setOnlyQrcode(true);
      setOnlyDelivery(false);
      setIsDeliveryAllowed(true);
      setIsPixAllowed(false);
      setIsCreditCardAllowed(false);
      setNfceCheck(false);
      setClubeCheck(false);
      setUseWpp(false);
      setHasBalcony(false);
    }

    if (e === "INTERMEDIÁRIO") {
      setOnlyQrcode(true);
      setOnlyDelivery(false);
      setIsDeliveryAllowed(true);
      setIsPixAllowed(false);
      setIsCreditCardAllowed(false);
      setNfceCheck(false);
      setClubeCheck(false);
      setHasBalcony(true);
      setUseWpp(false);
    }

    if (e === "INOVAÇÃO") {
      setOnlyQrcode(false);
      setOnlyDelivery(false);
      setIsDeliveryAllowed(true);
      setIsPixAllowed(false);
      setIsCreditCardAllowed(false);
      setNfceCheck(false);
      setClubeCheck(false);
      setUseWpp(false);
      setHasBalcony(true);
    }

    if (e === "PRO") {
      setOnlyQrcode(false);
      setOnlyDelivery(false);
      setIsDeliveryAllowed(true);
      setIsPixAllowed(false);
      setIsCreditCardAllowed(false);
      setNfceCheck(true);
      setClubeCheck(true);
      setUseWpp(true);
      setHasBalcony(true);
    }
  }

  function validateWppReport(active, test, expires){
    let toReturn = '';

    if(active && !test){
      toReturn = `Teste encerrado em ${format(new Date(expires), 'dd/MM/yyyy - HH:mm')}`;
    }else{
      if(expires){
        toReturn = `Período de teste até ${format(new Date(expires), 'dd/MM/yyyy - HH:mm')}`;
      }else{
        toReturn = 'Primeiro relatório ainda não enviado. Validade de 21 dias após a primeira mensagem.'
      }
    }

    return toReturn;
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />

      <div className="content">
        <Row style={{ marginBottom: 20 }}>
          <Col md="12">
            <ButtonTab
              thisButton={1}
              activeButton={buttonTab}
              onClick={() => setButtonTab(1)}
            >
              Informações Gerais
            </ButtonTab>
            <ButtonTab
              thisButton={2}
              activeButton={buttonTab}
              onClick={() => {
                setButtonTab(2);
                getDeletedMenu();
              }}
            >
              Recuperar Cardápio
            </ButtonTab>
          </Col>
        </Row>

        {buttonTab === 1 && (
          <>
            <Row>
              <Col md="3">
                <Button color="default" onClick={() => history.goBack()}>
                  <span className="btn-label">
                    <i className="nc-icon nc-minimal-left" />
                  </span>
                  Voltar
                </Button>
              </Col>

              <Col>
                <Button
                  color="danger"
                  onClick={toggleModal}
                  style={{ float: "right" }}
                >
                  Apagar
                </Button>
                <Button
                  color="warning"
                  onClick={toggleModalPassword}
                  style={{ float: "right", marginRight: 10 }}
                >
                  Alterar Senha
                </Button>
                <Button
                  onClick={deleteMenu}
                  style={{
                    float: "right",
                    marginRight: 10,
                    background: "#95340E",
                  }}
                >
                  Apagar Cardápio
                </Button>
                <Button
                  onClick={handleDownloadSheet}
                  style={{
                    float: "right",
                    marginRight: 10,
                    background: "#95340E",
                  }}
                >
                  Planilha Fiscal
                </Button>
              </Col>
            </Row>
            <Form
              ref={formRef}
              initialData={initialData}
              onSubmit={updateProfile}
            >
              <Row>
                <Col md="4">
                  <Row>
                    <div
                      className="image"
                      style={{ placeContent: "center", padding: 30 }}
                    >
                      <AvatarInputGd name="avatar_id" />
                    </div>
                  </Row>

                  {user.user_id !== 53952 &&
                    <Row
                      style={{
                        display: "flex",
                        width: 300,
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "-20px 0px 35px 0px",
                      }}
                    >
                      <Switch
                        onChange={changeIsActive}
                        checked={isActive}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={10}
                        width={30}
                        handleDiameter={20}
                        activeBoxShadow="0 0 2px 3px #111"
                        boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                      />

                      <span style={{ marginLeft: 10 }}>
                        {isActive ? "Ativo" : "Inativo"}
                      </span>
                    </Row>
                  }

                  {/* 
                  <Row>
                    <CashierDetails restaurantId={id} />
                  </Row> */}

                  <Card style={{ padding: 20 }}>
                    <Row style={{ margin: "auto" }}>
                      <p>
                        <strong>Cardápio via Excel</strong>
                      </p>
                    </Row>
                    <Row>
                      <Input
                        name="excel_file"
                        type="file"
                        onChange={(e) => handleSetFile(e)}
                      />
                    </Row>
                    <Row style={{ margin: "auto" }}>
                      <Button onClick={sendExcel}>Enviar</Button>
                    </Row>
                  </Card>

                  <Card style={{ padding: 20 }}>
                    <Row style={{ margin: "auto" }}>
                      <p>
                        <strong>Asaas</strong>
                      </p>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          {restaurant?.asaas_id && !asaasDefault ? (
                            <div style={{ textAlign: "center" }}>
                              <Button
                                onClick={() => getAsaas()}
                                disabled={loadingAsaas}
                              >
                                {loadingAsaas
                                  ? "Carregando..."
                                  : "Carregar Asaas"}
                              </Button>
                            </div>
                          ) : asaasDefault ? (
                            <Select
                              name="asaas_id"
                              options={asaasRestaurants}
                              defaultValue={asaasDefault}
                              onChange={(e) => setAsaasId(e.value)}
                            />
                          ) : (
                            <p style={{ textAlign: "center" }}>Carregando...</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>


                  <Card style={{ padding: 20 }}>
                    <Row style={{ margin: "auto" }}>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <CheckboxInput
                          checked={wppReportActive}           
                          name="wpp_report_active"
                          onChange={() => setWppReportActive(!wppReportActive)}
                        />
                        <strong>Relatório via WhatsApp</strong>
                      </div>
                    </Row>

                    <Row style={{marginTop: 10}}>
                      <Col md="12">
                        {validateWppReport(restaurant?.settings?.wpp_report_active, restaurant?.settings?.wpp_report_test, restaurant?.settings?.wpp_report_expires_at)}
                      </Col>
                    </Row>
                  </Card>

                  <Row>
                    <Col>
                      <Button onClick={createTablePos} color="link">
                        Criar mesa "Ficha"
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col md="8">
                  <Card>
                    <CardHeader>
                      <h5 className="title">Editar Perfil</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-1" md="7">
                          <label>Nome</label>
                          <Input
                            name="fantasy_name"
                            placeholder="Nome"
                            type="text"
                          />
                        </Col>
                        <Col className="pl-1" md="5">
                          <FormGroup>
                            <label>Username</label>
                            <Input
                              name="name"
                              placeholder="Username"
                              type="text"
                              value={name}
                              onChange={(e) =>
                                setName(
                                  e?.target?.value?.replace(/[^A-Z0-9_]/gi, "")
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="pr-1">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">E-mail</label>
                            <Input name="email" type="email" />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">Telefone</label>
                            <Input
                              name="phone"
                              placeholder="(xx) xxxxx-xxxx"
                              type="phone"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                          <label> WhatsApp Dono </label>
                          <FormGroup>
                            <ReactInputMask
                              mask="(99) 99999-9999"
                              value={ownerWpp}
                              onChange={e => setOwnerWpp(e.target.value)}
                            >
                              {()=>(
                                <Input type="text" name="owner_wpp"/>
                              )}
                            </ReactInputMask>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="9">
                          <FormGroup style={{marginTop: 9}}>
                            <Select
                              name="responsible_user_id"
                              options={users}
                              label="Usuário Responsável"
                              value={
                                users.find(
                                  (user) => user.value === responsibleUser
                                ) || []
                              }
                              onChange={(e) => setResponsibleUser(e.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <label> Instagram </label>
                          <Input
                            title="Usuário do instagram do restaurante."
                            name="instagram"
                            type="text"
                            placeholder="Usuário do restaurante"
                            value={instagram}
                            onChange={(e) =>
                              setInstagram(e?.target?.value?.replace("@", ""))
                            }
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Apelido"
                            checked={hasNickname}                            
                            name="has_nickname"
                            onChange={() => setHasNickname(!hasNickname)}
                          />
                        </Col>
                      </Row>

                      {hasNickname && (
                        <Row>
                          <Col md="12">
                            <Input
                              title="Apelido"
                              name="nickname"
                              type="text"
                              placeholder="Apelido"
                              defaultValue={nickname}
                            />
                          </Col>
                        </Row>
                      )}

                      <RowDivider title="Localização" />

                      <Row>
                        <Col md="4">
                          <label>CEP</label>
                          {/* <InputMask
                        mask="99999-999"
                        onChange={(e) => handleSearchCep(e)}                
                    > */}

                          <Input
                            name="zip_code"
                            onChange={(e) => handleSearchCep(e)}
                          />

                          {/* </InputMask> */}
                          {!validCep && (
                            <span style={{ color: "red" }}>CEP Inválido</span>
                          )}
                        </Col>
                        <Col md="4">
                          <label>Logradouro</label>
                          <Input name="street" type="text" />
                        </Col>

                        <Col md="4">
                          <label>Número</label>
                          <Input name="number" type="text" />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <label>Bairro</label>
                          <Input name="neighborhood" type="text" />
                        </Col>

                        <Col md="4">
                          <label>Cidade</label>
                          <Input name="city" type="text" />
                        </Col>

                        <Col md="4">
                          <label>Estado</label>
                          <Input name="state" type="text" />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          {locationLimitedCheck ? (
                            <CheckboxInput
                              label="Serviço de Geolocalização"
                              checked={locationLimitedCheck}
                              name="geolocation_cb"
                              onChange={changeLocationCheck}
                            />
                          ) : (
                            <CheckboxInput
                              label="Serviço de Geolocalização"
                              name="geolocation_cb"
                              onChange={changeLocationCheck}
                            />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="5">
                          <Label>Latitude</Label>
                          <FormGroup>
                            {locationLimitedCheck ? (
                              <Input
                                type="float"
                                name="latitude"
                                id="latitude"
                                onChange={(e) => setLatitude(e.target.value)}
                              />
                            ) : (
                              <Input
                                type="float"
                                name="latitude"
                                id="latitude"
                                disabled
                                onChange={(e) => setLatitude(e.target.value)}
                              />
                            )}
                          </FormGroup>
                          {locationLimitedCheck && (
                            <LinkStyled onClick={locationHere}>
                              Localização Atual
                            </LinkStyled>
                          )}
                        </Col>
                        <Col sm="5">
                          <Label>Longitude</Label>
                          <FormGroup>
                            {locationLimitedCheck ? (
                              <Input
                                type="float"
                                name="longitude"
                                id="longitude"
                                onChange={(e) => setLongitude(e.target.value)}
                              />
                            ) : (
                              <Input
                                type="float"
                                name="longitude"
                                id="longitude"
                                disabled
                                onChange={(e) => setLongitude(e.target.value)}
                              />
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <div style={{ marginTop: 20 }}>
                            <Button onClick={searchLocation} color="info">
                              <i className="nc-icon nc-zoom-split" />
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      {locationLimitedCheck && (
                        <>
                          <Label>Geolocalização:</Label>
                          <Map
                            center={inicialLocation}
                            zoom={14}
                            style={{ height: 250 }}
                            onClick={handleMapClick}
                          >
                            <TileLayer
                              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={selectedPosition}></Marker>
                          </Map>
                        </>
                      )}

                      <RowDivider title="Predefinição de plano" />
                      <Row>
                        <Col sm="12">
                          <Select
                            name="type_of_plan"
                            options={typeOfPlansOptions}
                            placeholder="Selecione o plano"
                            onChange={(e) => handleChangeTypeOfPlans(e.value)}
                            defaultValue={plan}
                            key={plan}
                          />
                        </Col>
                      </Row>

                      <RowDivider title="NFe|Clube" />

                      <Row>
                        <Col sm="12">
                          {nfceCheck ? (
                            <CheckboxInput
                              label="Emissão de NFCe"
                              checked={nfceCheck}
                              name="has_nfce"
                              onChange={changeNfceCheck}
                            />
                          ) : (
                            <CheckboxInput
                              label="Emissão de Nota Fiscal"
                              name="has_nfce"
                              onChange={changeNfceCheck}
                            />
                          )}
                        </Col>
                      </Row>

                      {nfceCheck && (
                        <>
                          <Row>
                            <Col className="pr-1" md="4">
                              <label>Token de Produção</label>
                              <Input
                                name="nfce_token"
                                placeholder="Token"
                                type="text"
                              />
                            </Col>
                            <Col md="4">
                              <label>CNPJ</label>
                              <InputMask mask="99.999.999/9999-99" defaultValue={initialData.cnpj}>
                                {()=>(
                                  <Input
                                    name="cnpj"
                                    type="text"
                                    placeholder="xx.xxx.xxx/xxxx-xx"
                                  />
                                )}
                              </InputMask>
                            </Col>
                            <Col md="4">
                              <FormGroup style={{ marginTop: 5 }}>
                                <Select
                                  name="regime_tributario"
                                  options={regimeTributarioOptions}
                                  label="Regime Tributário"
                                  defaultValue={
                                    regimeTributarioOptions.find(
                                      (user) =>
                                        user.value ===
                                        initialData.regime_tributario
                                    ) || []
                                  }
                                  // onChange={e => setResponsibleUser(e.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row>
                        <Col sm="12">
                          {clubeCheck ? (
                            <CheckboxInput
                              label="Takeat Club Ativo"
                              checked={clubeCheck}
                              name="has_clube"
                              onChange={changeClubeCheck}
                            />
                          ) : (
                            <CheckboxInput
                              label="Takeat Club Ativo"
                              name="has_clube"
                              onChange={changeClubeCheck}
                            />
                          )}
                        </Col>
                      </Row>

                      {clubeCheck && (
                        <Row>
                          <Col className="pr-1" md="7">
                            <label>Token Takeat Club</label>
                            <Input
                              name="token_clube"
                              placeholder="Token"
                              type="text"
                            />
                          </Col>
                          <Col md="5">
                            <Button
                              color="danger"
                              type="button"
                              onClick={() => setDeleteClubeModal(true)}
                            >
                              Deletar clube
                            </Button>
                          </Col>
                        </Row>
                      )}

                      <RowDivider title="Pagamento Online" />
                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Pix Online"
                            checked={!!isPixAllowed}
                            name="has_pix"
                            onChange={changePixAllowed}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Cartão de Crédito Online"
                            checked={!!isCreditCardAllowed}
                            name="has_credit_card"
                            onChange={changeCreditCardAllowed}
                          />
                        </Col>
                      </Row>
                      {(isPixAllowed || isCreditCardAllowed) && (
                        <Row>
                          <Col md="12">
                            <Input
                              name="paytime_seller_id"
                              placeholder="Token"
                              type="text"
                            />
                          </Col>
                        </Row>
                      )}

                      <RowDivider title="Cardápio Digital" />

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Habilitar pedidos via QR Code"
                            checked={!onlyQrcode}
                            name="only_qrcode"
                            onChange={changeOnlyQrcode}
                            disabled={onlyQrcodeDisabled}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <CheckboxInput
                            label="Habilitar Balcão"
                            name="has_balcony"
                            checked={hasBalcony}
                            onChange={changeBalconyCheck}
                          />
                        </Col>
                      </Row>

                      <RowDivider title="Delivery" />

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Apenas Delivery"
                            checked={!!onlyDelivery}
                            name="only_delivery"
                            onChange={changeOnlyDelivery}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Habilitar Delivery e Retirada"
                            checked={!!isDeliveryAllowed}
                            name="delivery_info.is_delivery_allowed"
                            onChange={changeDeliveryAllowed}
                            disabled={!!onlyDelivery}
                          />
                        </Col>
                      </Row>

                      {/* <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Habilitar Retirada"
                            checked={!!isWithdrawalAllowed}
                            name="delivery_info.is_withdrawal_allowed"
                            onChange={changeWithdrawalAllowed}
                          />
                        </Col>
                      </Row> */}

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Habilitar o cadastro dos endereços de entrega"
                            checked={!!isDeliveryAddressesAllowed}
                            name="delivery_info.allow_delivery_addresses"
                            onChange={changeDeliveryAddressesAllowed}
                          />
                        </Col>
                      </Row>

                      <div>
                        <RowDivider title="iFood" />
                        <Row>
                          <Col md="12">
                            <CheckboxInput
                              label="iFood ativo"
                              checked={restHasIfood}
                              name="has_ifood"
                              onChange={() => setRestHasIfood(!restHasIfood)}
                            />
                          </Col>
                        </Row>
                        {restHasIfood && (
                          <div>
                            <Row>
                              <Col md="5">
                                <Input
                                  title="iFood ID"
                                  name="ifood_merchant_id"
                                  type="text"
                                  placeholder="iFood ID"
                                  onChange={(e) =>
                                    setMerchantIfood(e.target.value)
                                  }
                                />
                              </Col>
                              <Col md="5">
                                <Input
                                  title="Nome iFood"
                                  name="ifood_name"
                                  type="text"
                                  placeholder="Nome iFood"
                                  onChange={(e) => setNameIfood(e.target.value)}
                                />
                              </Col>
                              <Col md="1">
                                <Button
                                  type="button"
                                  style={{
                                    background: "red",
                                  }}
                                  onClick={() => handleAddIfoodId()}
                                >
                                  +
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="11">
                                <table
                                  style={{
                                    width: "100%",
                                    marginBottom: 15,
                                  }}
                                >
                                  {ifoodRestaurants.map((rest) => (
                                    <TrIfoodRestaurants>
                                      <td>{rest.ifood_merchant_id}</td>-{" "}
                                      <td style={{ padding: "5px 0" }}>
                                        <strong>{rest.name}</strong>
                                      </td>
                                      <td>
                                        <FaTrash
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDeleteIfoodId(
                                              rest.ifood_merchant_id
                                            )
                                          }
                                        />
                                      </td>
                                    </TrIfoodRestaurants>
                                  ))}
                                </table>
                              </Col>
                            </Row>

                            <div style={{marginTop: 10}}>
                              <Row>
                                <Col md="12">
                                  <CheckboxInput
                                    label="Integração com Código PDV"
                                    checked={ifoodPdv}
                                    name="use_ifood_pdv_code"
                                    onChange={() => setIfoodPdv(!ifoodPdv)}
                                  />
                                </Col>
                              </Row>
                            </div>  
                          </div>
                        )}
                      </div>

                      <RowDivider title="Estoque" />

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Utiliza controle de estoque"
                            checked={!!useStock}
                            name="use_stock"
                            onChange={changeUseStock}
                          />
                        </Col>
                      </Row>

                      <RowDivider title="Totem / TEF PDV" />
                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Totem"
                            checked={!!hasStonePos}
                            name="has_stone_pos"
                            onChange={() => setHasStonePos(!hasStonePos)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="TEF PDV"
                            checked={!!hasStonePdv}
                            name="has_stone_pdv"
                            onChange={() => setHasStonePdv(!hasStonePdv)}
                          />
                        </Col>
                      </Row>
                      {(hasStonePos || hasStonePdv) && (
                        <Row style={{marginTop: 5}}>
                          <Col md="12">
                            <Input
                              name="sk_stone"
                              placeholder="Secret Key Stone"
                              type="text"
                              label="Secret Key Stone"
                              defaultValue={skStone}
                            />
                          </Col>
                        </Row>
                      )}

                      {tableLimitType !== "unlimited-tables" && (
                        <>
                          <RowDivider title="Mesas" style={{ marginTop: 15 }} />

                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Select
                                  name="table_limit_type"
                                  options={tableOptions}
                                  label="Limitar mesas"
                                  value={tableLimitTypeDefault}
                                  onChange={(e) => {
                                    setTableLimitType(e.value);
                                    e.value === "limited-tables"
                                      ? setIsTableLimitDisabled(false)
                                      : setIsTableLimitDisabled(true);
                                    setTableLimitTypeDefault(e);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <Input
                                name="table_limit"
                                label="Limitar mesas"
                                disabled={isTableLimitDisabled}
                                value={tableLimitValue}
                                type="number"
                                onChange={(e) =>
                                  setTableLimitValue(e.target.value)
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <RowDivider title="Automação do Whatsapp" />
                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Habilitar automação de whatsapp"
                            checked={useWpp}
                            name="use_wpp"
                            onChange={changeUseWpp}
                          />
                        </Col>
                      </Row>

                      <RowDivider title="Smart POS" />
                      <Row>
                        <Col sm="12" style={{ marginTop: 10 }}>
                          <CheckboxInput
                            label="Utilizar smart POS"
                            name="use_smart_pos"
                            onChange={changeUseSmartPos}
                            checked={useSmartPos}
                          />
                        </Col>
                      </Row>

                      <RowDivider title="Tablet" />
                      <Row>
                        <Col sm="12" style={{ marginTop: 10 }}>
                          <CheckboxInput
                            label="Utiliza Tablet"
                            name="use_tablet"
                            onChange={() => setUseTablet(!useTablet)}
                            checked={useTablet}
                          />
                        </Col>
                      </Row>

                      <RowDivider title="SMS" />

                      <Row>
                        <Col md="12">
                          <label> Serviço de SMS (R$) </label>
                          <Input
                            title="Número de até 2 casas decimais."
                            pattern="\.\d{1,2}|\d{1,}(\.\d{1,2})?"
                            name="delivery_info.sms_service_price"
                            type="text"
                            placeholder="0.00"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Painel de Senha Eletrônica"
                            name="delivery_info.is_sms_service_optional"
                            checked={passwordPanel}
                            onChange={changePasswordPanel}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="SMS Disponível"
                            name="delivery_info.has_sms_service"
                            checked={smsService}
                            onChange={changeSmsService}
                            disabled={!passwordPanel}
                          />
                        </Col>
                      </Row>

                      {smsService && (
                        <Row>
                          <Col md="12">
                            <CheckboxInput
                              label="Sms Pago pelo Cliente"
                              name="delivery_info.client_pay_sms"
                              checked={clientPaySms}
                              onChange={changeClientPaySms}
                            />
                          </Col>
                        </Row>
                      )}

                      <RowDivider title="Aviso de Pagamento" />
                      <Row>
                        <Col md="12">
                          <CheckboxInput
                            label="Ativar aviso de pagamento"
                            checked={suspensionAlert}
                            name="suspension_alert"
                            onChange={changeSuspensionAlert}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 15 }}>
                        <Col md="4" className="ml-auto">
                          <Button color="success" type="submit">
                            Atualizar Perfil
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>

            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader> Apagar Restaurante </ModalHeader>
              <ModalBody>
                <Container>
                  Deseja realmente apagar o restaurante{" "}
                  <b>{initialData.fantasy_name}</b>?
                  <p>
                    {" "}
                    Ao confirmar, você estará removendo este restaurante do
                    sistema, assim como todos os{" "}
                    <i style={{ color: "yellow" }}>
                      pedidos, produtos, mesas, garçons e etc
                    </i>
                    , que estejam associados a ele.{" "}
                  </p>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={deleteRestaurant}>
                  Confirmar
                </Button>{" "}
                <Button color="danger" onClick={toggleModal}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalPassword} toggle={toggleModalPassword}>
              <ModalHeader> Alterar senha </ModalHeader>
              <ModalBody>
                <Form>
                  <Container>
                    <Row>
                      <Col md="12">
                        <label> Nova Senha </label>
                        <Input
                          name="password"
                          type="text"
                          placeholder="Mínimo 6 caracteres"
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={updatePassword}>
                  Confirmar
                </Button>{" "}
                <Button color="danger" onClick={toggleModalPassword}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={deleteClubeModal}
              toggle={() => setDeleteClubeModal(!deleteClubeModal)}
            >
              <ModalHeader>Deletar clube</ModalHeader>
              <ModalBody>
                <Container>
                  Deseja realmente deletar o clube do restaurante{" "}
                  <b>{initialData.fantasy_name}</b>?
                  <p> Para confirmar digite "DELETAR" e clique no botão.</p>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Form
                  onSubmit={submitDeleteClube}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Input
                    name="confirm_delete"
                    value={confirmDelete}
                    onChange={(e) => setConfirmDelete(e.target.value)}
                  />
                  <Button color="danger" type="submit">
                    Deletar
                  </Button>
                </Form>
              </ModalFooter>
            </Modal>
          </>
        )}

        {buttonTab === 2 && (
          <RecoverMenu
            menu={deletedMenu}
            loading={loadingDeletedMenu}
            getDeletedMenu={getDeletedMenu}
            notify={notify}
          />
        )}
      </div>
    </>
  );
}

export default EditRestaurantGD;
